<div class="d-flex justify-content-between align-items-center pb-2" mat-dialog-title>
  <h2 class="mb-0">{{ data.title }}</h2>
  <mat-icon fontSet="material-symbols-outlined" class="pointer" (click)="close()">close</mat-icon>
</div>
<div mat-dialog-content>
  @if (data.extra_type === 'email') {
    <div class="d-flex flex-column mb-5 mt-3">
      <span class="mb-3">Per consegnare la simulazione inserisci il tuo indirizzo mail</span>
      <mat-form-field appearance="outline">
        <!-- <mat-label>{{'profile.edit.name' | transloco}}</mat-label> -->
        <mat-label>{{ 'profile.edit.email' | transloco }}</mat-label>
        <input type="name" [name]="'profile.edit.email' | transloco" matInput [(ngModel)]="email" />
        <!-- <mat-error>{{'utils.errors.required_field' | transloco}}</mat-error> -->
      </mat-form-field>
    </div>
  }
  <div [innerHtml]="data.content" class="mt-2"></div>
</div>

<div class="w-100 d-flex justify-content-end align-items-center mt-3">
  <button futAnalyticsButton eventAction="update" eventTarget="insert" (click)="confirm()" [disabled]="!isValid()" class="fut-btn fut-btn-accent fut-bold ml-2">
    {{ data.button_title }}
  </button>
</div>
